import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Sun, Moon, Menu, X } from 'lucide-react';
import './index.css';
import CompactDashboard from './CompactDashboard';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Support from './Support';
import Careers from './Careers';
import About from './About';
import ScrollToTop from './ScrollToTop';

const PediatricDashboardLandingPage = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const colors = {
    background: isDarkMode ? 'bg-[#1e293b]' : 'bg-[#f8fafc]',
    textPrimary: isDarkMode ? 'text-[#e2e8f0]' : 'text-[#2d3748]',
    textSecondary: isDarkMode ? 'text-[#94a3b8]' : 'text-[#718096]',
    accentBlue: isDarkMode ? 'text-[#60a5fa]' : 'text-[#3b82f6]',
    cardBackground: isDarkMode ? 'bg-[#2c3e50]' : 'bg-[#ffffff]',
  };

  const toggleTheme = () => setIsDarkMode(!isDarkMode);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const closeMenuOnOutsideClick = (e) => {
      if (isMenuOpen && !e.target.closest('.menu-button') && !e.target.closest('.mobile-menu')) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('click', closeMenuOnOutsideClick);
    return () => document.removeEventListener('click', closeMenuOnOutsideClick);
  }, [isMenuOpen]);

  return (
    <Router>
      <ScrollToTop />
      <div className={`${colors.background} ${colors.textPrimary} min-h-screen`}>
        {/* Header Section */}
        <header className={`sticky top-0 ${colors.background} py-4 z-50 shadow-md`}>
          <div className="max-w-6xl mx-auto px-4 flex items-center justify-between">
            <Link
              to="/"
              className="text-2xl font-semibold tracking-tight hover:opacity-90 transition-opacity"
              onClick={() => {
                scrollToTop();
                setIsMenuOpen(false);
              }}
            >
              SmileCare Analytics
            </Link>

            <button
              onClick={toggleTheme}
              className={`ml-4 p-2 rounded-full transition-all ${colors.background} hover:bg-opacity-20 shadow-md`}
              aria-label="Toggle Theme"
            >
              {isDarkMode ? (
                <Sun className="h-6 w-6 text-yellow-400 transition-colors" />
              ) : (
                <Moon className="h-6 w-6 text-blue-400 transition-colors" />
              )}
            </button>

          </div>

        </header>

        {/* Routes Setup */}
        <Routes>
          <Route path="/" element={<MainContent isDarkMode={isDarkMode} colors={colors} />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy isDarkMode={isDarkMode} />} />
          <Route path="/terms-of-service" element={<TermsOfService isDarkMode={isDarkMode} />} />
          <Route path="/support" element={<Support isDarkMode={isDarkMode} />} />
          <Route path="/careers" element={<Careers isDarkMode={isDarkMode} />} />
          <Route path="/about" element={<About isDarkMode={isDarkMode} />} />
        </Routes>

        {/* Footer Section */}
        <footer className={`py-6 px-4 md:px-8 ${isDarkMode ? 'bg-slate-800 text-gray-400' : 'bg-gray-100 text-gray-600'}`}>
          <div className="max-w-6xl mx-auto text-center text-sm">
            © {new Date().getFullYear()} SmileCare Analytics. All rights reserved.
          </div>
        </footer>
      </div>
    </Router>
  );
};

const MainContent = ({ isDarkMode, colors }) => {
  return (
    <div className="flex flex-col items-center  min-h-screen pt-24 pb-12 px-6">
      {/* Announcement Section */}
      <section className="w-full max-w-3xl text-center space-y-8 animate-fade-in">
        <h1 className="text-4xl md:text-4xl font-extrabold leading-tight bg-gradient-to-r from-blue-500 to-teal-400 text-transparent bg-clip-text">
          A New Chapter Begins
        </h1>
        <div className={`${colors.cardBackground} rounded-2xl p-10 shadow-2xl transition-all duration-300 hover:shadow-3xl`}>
          <p className={`text-lg ${colors.textPrimary} mb-6 leading-relaxed`}>
            We’re excited to announce that <span className="font-semibold">SmileCare Analytics</span> has been acquired! To our incredible customers: thank you for your unwavering support. Your trust has been the cornerstone of our journey, and we’re deeply grateful for every moment.
          </p>
          <p className={`text-lg ${colors.textPrimary} mb-6 leading-relaxed`}>
            As we embark on this exciting transition, rest assured that our team will be in touch soon to walk you through the next steps. We’re committed to making this process seamless and ensuring your practice continues to thrive.
          </p>
          <p className={`text-lg ${colors.textPrimary} leading-relaxed`}>
            Questions? Reach out anytime at{' '}
            <a href="mailto:info@smilecareanalytics.com" className={`${colors.accentBlue} font-medium hover:underline transition-all duration-200`}>
              info@smilecareanalytics.com
            </a>.
          </p>
        </div>
        <p className={`text-sm ${colors.textSecondary} italic`}>
          Stay tuned for more updates as we shape the future together!
        </p>
      </section>
    </div>
  );
};

export default PediatricDashboardLandingPage;